<template>
<!-- zx 2021 5 31 -->
<div>
<!-- <div class="bg1"  v-lazy:background-image="require('./virtual_museum_img/pic_1.png')"> -->
<div class="bg1">
  <div class="start">
   <img v-lazy ="require('./virtual_museum_img/FOdlHqwb.webp')" class="start-img">
 </div>
 <div class="bannert">
  <div class="txt1" >
    虚拟博物馆
  </div>
  <div class="txt2"> 
    结合了虚拟现实技术等多种高科技手段，在线观看畅游博物馆，
                <br />
                体验互联网3D时代颠覆传统B2B模式
  </div>
  </div>
</div>
<div class="bg2">
  <div class="txt3">云上博物馆线上巡游</div>
  <div class="txt4"> 使用3D漫游、沉浸式体验、数据及信息实时展示等技术，通过富媒体交互展示与创意设计的有机结合，
          <br />
          为观众打造一个充满艺术感、互动感、新鲜感的沉浸式体验环境
  </div>
  <diV class="txt5">
          <img src="" 
           v-lazy="require('./virtual_museum_img/ttnblR3H.webp')" class="pictrue1">
  </diV>
  <div class="txt11">
    <div class="txt8">
      <div>
      <img src="./virtual_museum_img/pic_5.png" class="txt6">
      <div class="txt9">表现无限制</div>
      <div class="txt10"> 使用WebGL&nbsp;3D引擎搭建真3D虚拟场馆。突破现实物理限制。综合运用富媒体技术，使访问者得到如梦似幻的体验。</div>
    </div>
    </div>
     <div class="txt8 ml30">
      <div>
      <img src="./virtual_museum_img/pic_7.png" class="txt6">
      <div class="txt9">访问无限制</div>
      <div class="txt10">访问者只需一个网络连接，便可通过手机、电脑、VR眼镜等多端设备进行访问。突破传统线下展厅物理空间的限制。</div>
    </div>
    </div>
     <div class="txt8 ml30">
      <div>
      <img src="./virtual_museum_img/pic_6.png" class="txt6">
      <div class="txt9">传播无限制</div>
      <div class="txt10"> 线上展厅访问人数无限制，地理位置无限制，可营造全球触达。可通过微信朋友圈等社交媒体进行分享，达成传播裂变效果。</div>
    </div>
    </div>
  </div>
</div>
<!-- <div class="bg3" v-lazy:background-image="require('./virtual_museum_img/pic_4.png')"> -->
<div class="bg3">
 <div class="img-4">
   <img v-lazy ="require('./virtual_museum_img/rg7wy4eY.webp')" class="img-5">
 </div>
 <div class="literal1">
  <div class="txt13">
    VR游戏
  </div>
  <div class="txt14">
    带上虚拟现实设备,就可以让你进入一个可交互的虚拟现场场景中,你看到的就是游戏的世界，不管你怎
                  <br />
                  么转动视线，你都位于游戏里
  </div>
  </div>
</div>
<div class="bg4">
 <div class="txt3">
   虚拟现实
 </div>
 <div  class="txt4"> 虚拟现实不仅仅是一种技术，更构建出了未来游戏的全新图景。它赋予游戏玩家更身临其境的带入感，
          <br />
          使得游戏从平面真正走向立体
 </div>
 <div class="img-7">
   <img 
 v-lazy="require('./virtual_museum_img/bR49mD7F.webp')"
  class="pic_3">
 </div>
</div>
<!-- <div class="bg5">
  <img src="./virtual_museum_img/pic_8.png" class="pic_4">
</div> -->
 <div>
  <Header></Header>
  <Footer></Footer>

  </div>
</div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped> 
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.bg1{
  height: 600px;
  /* width: 100%; */
  /* background-repeat:  no-repeat; */
  /* background-size: 100% 100%; */
}
.start-img{
   height: 600px;
}
.start{
   height: 600px;
   width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
     width: 100%;
	}
}
.bannert{
  position: absolute;
    left: 361px;
    top: 230px;
}
.txt1{
   color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // padding-top: 230px;
  // padding-left: 360px;
 text-align: left;
}
.txt1{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.txt2{
   color: rgba(255, 255, 255, 1);
  font-size: 24px;
  text-align: left;
  margin-top: 20px;
  // padding-left: 360px;
}
.txt2{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.bg2{
  margin: auto;
}
.txt3{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  margin-top: 80px;
}
.txt4{
  color: rgba(95, 95, 95, 1);
  font-size: 20px;
  margin-top: 24px;
  line-height: 40px;
}
.txt5{
   height: 552px;
  border-radius: 30px;
  background-color: rgba(51, 51, 51, 1);
  align-self: center;
  margin-top: 50px;
  width: 1201px;
  margin: auto;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
    img[lazy="loading"] {
		width: 100px;
		height: 100px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 525px;
     width: 1173px;
	}
}
.pictrue1{
   width: 1173px;
  height: 523px;
}
.txt6{
 width: 108px;
 height: 108px;
}
.txt8{
   height: 314px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt9{
   color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
}
.txt10{
   color: rgba(153, 153, 153, 1);
  font-size: 14px;
  margin: 16px 48px 0 48px;
  text-align: left;
}
.txt11{
  display: flex;
  justify-content: center;
  margin-bottom: 100PX;
}
.ml30{
  margin-left: 30px;
}
.bg3{
  height: 720px;
  /* width: 100%; */
  // background-repeat:  no-repeat;
  // background-size: 100% 100%;
  position: relative;

}
.literal1{
  position: absolute;
  top: 280px;
  left: 492px;
}
.img-5{
   height: 720px;
   width: 100%;

}
.img-4{
   height: 720px;
   width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 720px;
     width: 100%;
	}
}
.txt13{
   color: rgba(255, 255, 255, 1);
  font-size: 40px;
  // padding-top: 280px;
}
.txt14{
   color: rgba(255, 255, 255, 1);
  font-size: 20px;
  line-height: 40px;
  margin-top: 24px;
  
}
.pic_3{
width: 1200px;
height: 422px;

}
.img-7{
  width: 1200px;
height: 422px;
margin: auto;
margin-top: 60px;
margin-bottom: 100px;

display: flex;
justify-content: center;
align-items: center;
    img[lazy="loading"] {
		width: 100px;
		height: 100px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		   width: 1200px;
height: 422px;
	}
}
.pic_4{
  width: 508px;
  height: 98px;
}
.bg5{
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>